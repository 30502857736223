import React from "react";
import { Link } from "gatsby";

const links = [
  { to: "/about", label: "About" },
  { to: "/services", label: "Services" },
  { to: "/projects", label: "Projects" },
  { to: "/contact", label: "Contact" },
];

const NavLink = ({ to, children }) => {
  return (
    <Link to={to} className="hpadding-m vpadding-s inline-block text-white">
      {children}
    </Link>
  );
};

const Nav = () => {
  return (
    <nav className="bg-orange">
      <ul className="almost-full-width flex space-between unstyled-list">
        <li>
          <NavLink to="/">The Fred‿Space</NavLink>
        </li>
        <div className="flex space-between">
          {links.map(({ to, label }) => (
            <li key={label}>
              <NavLink to={to}>{label}</NavLink>
            </li>
          ))}
        </div>
      </ul>
    </nav>
  );
};

export default Nav;
