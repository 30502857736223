import React from "react";
import Nav from "./Nav";
import "../styles/reset.css";
import "../styles/blocks.css";
import "../styles/global.css";
import "../styles/typography.css";

const Layout = ({ children }) => {
  return (
    <div>
      <Nav />
      <main className="container vpadding-l">{children}</main>
    </div>
  );
};

export default Layout;
