import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import Emoji from "../components/Emoji";
import "./index.css";

// TODO: focus correct part of image
export const query = graphql`
  query {
    file(relativePath: { eq: "fred-portrait-square-shopped.jpeg" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const INFO = [
  {
    title: "simple",
    body: `\
I use the right tool for the job. My versatility means we'll avoid putting square pegs into round holes. I write idiomatic code in a functional style, with an emphasis on readability. Consistent refactoring helps my teams to avoid code bloat. Less code means less to worry about.`,
  },
  {
    title: "sustainable",
    body: `\
If a job is worth doing, it's worth doing well. Excellent test coverage ensures a robust, well-documented codebase that will thrive for years to come. Having led successful teams of all kinds, I know how vital it is to build a foundation of clear, open communication.`,
  },
  {
    title: "software",
    body: `\
I don't just write code, I deliver software. Whether it's the 1st deployment or the 1000th, you can be confident of a smooth rollout. With years of experience in CI/CD and automation best practices, I can help teams to deliver more quickly, more safely and more often.`,
  },
];

const Hero = ({ data }) => {
  return (
    <section className="flex hstack-m">
      <Img fixed={data.file.childImageSharp.fixed} className="face" />
      <div>
        <h1>
          <Emoji symbol="wave" />
          {" Hi, I'm Fred"}
        </h1>
        <span className="text-l text-orange">
          a lean, <span className="text-green">green</span> software machine
        </span>
        <div>
          I craft delightful web experiences, from front to back.
          {
            // <div>
            // <button>
            //   Spark joy <Emoji symbol="wand" />
            // </button>
            // </div>
          }
        </div>
      </div>
    </section>
  );
};

const Purpose = () => {
  return (
    <section className="switcher">
      {INFO.map(({ title, body }) => {
        return (
          <Card key={title} title={title}>
            {body}
          </Card>
        );
      })}
    </section>
  );
};

const Card = ({ title, children }) => {
  return (
    <div className="padding-m">
      <h2 className="text-green">{title}</h2>
      <p>{children}</p>
    </div>
  );
};

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Hero data={data} />
      <Purpose />
    </Layout>
  );
};

export default IndexPage;
