import React from "react";

const MAP = {
  wand: "🪄",
  wave: "👋",
  stack: "🥞",
  ship: "🚢",
  juggling: "🤹",
  graph: "📈",
};

const Emoji = ({ symbol }) => {
  const found = MAP[symbol];
  if (!found) {
    throw new Error(`unexpected symbol ${symbol}`);
  }
  return <span className="font-stack-system">{found}</span>;
};

export default Emoji;
